import {auth, database, functions, storage} from "../../../firebase";
import {deleteObject, getDownloadURL, getMetadata, ref as firebaseStorageRef} from "firebase/storage";
import {get, onValue, ref as databaseRef} from "firebase/database";
import {httpsCallable} from "firebase/functions";
import {getIdTokenResult, signOut} from "firebase/auth";

// Set custom claims for a user
export const setClaims = async (id, isEmail, claim) => {
    try {
        const adminFunction = httpsCallable(functions, 'setCustomClaim');
        return await adminFunction({ id, isEmail, claim });
    } catch (e) {
        throw e;
    }
};

// Set author claim
export const setAuthor = async (id, isEmail, isAuthor) => {
    return setClaims(id, isEmail, { admin: isAuthor });
};

// Disable a user
export const disableUser = async (id, isEmail, disabled) => {
    try {
        const adminFunction = httpsCallable(functions, 'disableUser');
        return await adminFunction({ id, isEmail, disabled });
    } catch (e) {
        throw e;
    }
};

// Fetch all files and their articles from Firebase
const fetchAllFiles = (setArticlesByCategory, setLoading) => {
    const articlesRef = databaseRef(database, 'articlesList');
    return onValue(articlesRef, async (snapshot) => {
        setLoading(true);
        try {
            if (!snapshot.exists()) {
                setArticlesByCategory({});
                setLoading(false);
                return;
            }
            const data = snapshot.val();
            const folders = Object.keys(data);
            const fetchFolderPromises = folders.map(async (folder) => {
                const folderSnapshot = await get(databaseRef(database, `articlesList/${folder}`));
                if (folderSnapshot.exists()) {
                    const articlesByCategory = Object.entries(folderSnapshot.val()).flatMap(([articleKey, articleCategory]) =>
                        Object.entries(articleCategory).map(([articleTitle, temp]) => ({
                            ...temp,
                            name: articleTitle,
                            category: articleKey || 'uncategorized',
                            folder
                        }))
                    );
                    return { [folder]: articlesByCategory };
                }
                return null;
            });
            const foldersData = await Promise.all(fetchFolderPromises);
            setArticlesByCategory(foldersData.reduce((acc, folderData) => folderData ? { ...acc, ...folderData } : acc, {}));
        } catch (error) {
            console.error('Error fetching files:', error);
        } finally {
            setLoading(false);
        }
    });
};

// Fetch files function
export async function fetchFiles(setFiles, setError, setAlreadyPublishedArticles, setAlreadyPublishedError, setEarlyReleasedArticles, setEarlyReleasesError, setLoading) {
    return fetchAllFiles(setFiles, setLoading);
}

// Get Firebase Storage URL with optional resizing
export const getFirebaseStorageUrlFull = async (fileName, shouldBeFull) => {
    try {
        if (shouldBeFull) {
            return await getDownloadURL(firebaseStorageRef(storage, fileName));
        } else {
            const metadata = await getMetadata(firebaseStorageRef(storage, fileName)).catch(() => null);
            if (metadata) {
                const shouldResize = await isAlmostRectangle(firebaseStorageRef(storage, fileName));
                return await getDownloadURL(firebaseStorageRef(storage, changeAnalysis(fileName, "800x800", "800x600", shouldResize.result)));
            } else {
                return await getDownloadURL(firebaseStorageRef(storage, fileName));
            }
        }
    } catch (error) {
        console.error('Error fetching image URL:', error);
        throw error;
    }
};

// Get file reference from URL
export function getRef(imageUrl, isFolderIncluded) {
    const imagePath = imageUrl.split("/");
    const fileName = imagePath.pop();
    const folder = isFolderIncluded ? imagePath.join("/") : "images";
    return `${folder}/${fileName}`;
}

// Get Firebase Storage URL from path with optional resizing
export const getFirebaseStorageUrlFromPath = async (imageUrl, isFolderIncluded) => {
    const shouldResize = await isAlmostRectangle(firebaseStorageRef(storage, imageUrl));
    const fileName = getRef(imageUrl, isFolderIncluded);
    try {
        return await getDownloadURL(firebaseStorageRef(storage, changeAnalysis(fileName, "800x800", "800x600", shouldResize.result)));
    } catch (e) {
        return await getDownloadURL(firebaseStorageRef(storage, fileName));
    }
}

// Get Firebase Storage URL with metadata storage
export const getFirebaseStorageUrl = async (imageUrl, setShouldStoreMetadata, setImageStorageRef) => {
    const fileName = getRef(imageUrl, false);
    const shouldResize = await isAlmostRectangle(firebaseStorageRef(storage, fileName));
    const storageReference = firebaseStorageRef(storage, changeAnalysis(fileName, "800x800", "800x600", shouldResize.result));
    if (setShouldStoreMetadata) setShouldStoreMetadata(!shouldResize.areMetadataFound);
    if (setImageStorageRef) setImageStorageRef(storageReference);
    try {
        return await getDownloadURL(storageReference);
    } catch (e) {
        console.error(e);
    }
};

// Delete image and related files
export function deleteImage(imageName) {
    return Promise.all(getAllImageNames(imageName).map(async (imageName) => {
        try {
            await deleteObject(firebaseStorageRef(storage, imageName));
            console.log(`Image ${imageName} deleted successfully from storage.`);
        } catch (e) {
            console.error(`Error deleting image ${imageName}:`, e);
        }
    }));
}

// Get all image names with different resolutions
export function getAllImageNames(imageName) {
    const dotIndex = imageName.lastIndexOf('.');
    const extensions = ["600x300", "600x600", "800x400", "800x600", "800x800"];
    const name = dotIndex === -1 ? imageName : imageName.substring(0, dotIndex);
    const imageExtension = dotIndex === -1 ? '' : imageName.substring(dotIndex);
    return extensions.map((extension) => `${name}_${extension}${imageExtension}`);
}

// Change analysis based on dimensions
function changeAnalysis(fileName, analysis_true, analysis_false, change_analysis) {
    const dotIndex = fileName.lastIndexOf('.');
    const name = dotIndex === -1 ? fileName : fileName.substring(0, dotIndex);
    const extension = dotIndex === -1 ? '' : fileName.substring(dotIndex);
    return `${name}_${change_analysis ? analysis_true : analysis_false}${extension}`;
}

// Check if the image is "almost rectangular"
const isAlmostRectangle = async (storageReference) => {
    try {
        const metadata = await getMetadata(storageReference);
        const width = parseInt(metadata.customMetadata.width, 10);
        const height = parseInt(metadata.customMetadata.height, 10);
        const tolerance = 0.5;
        const aspectRatio = width / height;
        return { result: Math.abs(aspectRatio - 1) <= tolerance, areMetadataFound: true };
    } catch (e) {
        return { result: false, areMetadataFound: false };
    }
};

// Get image dimensions from a file
export const getImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve({ width: img.width, height: img.height });
        img.onerror = reject;
        img.src = URL.createObjectURL(file);
    });
};

// Handle author test and navigation
export function handleAuthorTest(user, setCurrentUser, navigate) {
    if (user) {
        getIdTokenResult(user).then((idTokenResult) => {
            if (idTokenResult.claims?.admin) {
                console.log("The user is an admin");
            } else {
                signOut(auth).then(() => {
                    console.log("Trying to login again");
                    navigate('/upload/login');
                });
            }
        });
        setCurrentUser(user);
    } else {
        setCurrentUser(null);
        navigate('/upload/login');
    }
}

// List of categories
export const categories = [
    "Top News",
    "General News",
    "Interviews",
    "Collabs and Sponsorships",
    "Latest Reviews(ENG)",
    "Latest Reviews(GRE)",
    "Latest Reviews(IT)",
    "Legends"
];

// Set user IDs based on emails
export const setUids = async (userList, leaderList, setAdminUids, setLeadersUids) => {
    const authorsSnapshot = await get(databaseRef(database, 'authors'));
    const authorsData = authorsSnapshot.val();
    const findUids = (list) => list.map(email => Object.keys(authorsData).find(uid => authorsData[uid].email === email) || null);
    setAdminUids(findUids(userList).filter(Boolean));
    setLeadersUids(findUids(leaderList).filter(Boolean));
};