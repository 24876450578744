import React, { useEffect, useState } from 'react';
import { get, onValue, ref, remove, update } from 'firebase/database';
import { deleteObject, getStorage, ref as storageRef } from 'firebase/storage';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { auth, database } from "../../firebase";
import { deleteImage, getFirebaseStorageUrlFromPath } from "../UploadSystem/articleData/articleData";

const AdView = () => {
    const [ads, setAds] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const unsubscribeAuth = auth.onAuthStateChanged(async (user) => {
            if (user) {
                setCurrentUser(user);
                const adminRef = ref(database, 'roles/ads');
                const snapshot = await get(adminRef);
                if (snapshot.exists() && snapshot.val().includes(user.email)) {
                    setIsAdmin(true);
                }
            }
        });

        const adsRef = ref(database, 'ads');
        const unsubscribeAds = onValue(adsRef, async (snapshot) => {
            const adsData = snapshot.val();
            const adsList = await Promise.all(Object.keys(adsData).map(async (key) => {
                const ad = adsData[key];
                if (ad.imageURL) {
                    ad.imageURL = await getFirebaseStorageUrlFromPath(ad.imageURL, true);
                }
                return { ...ad, key };
            }));
            setAds(adsList);
        });

        return () => {
            unsubscribeAuth();
            unsubscribeAds();
        };
    }, []);

    const handleApproval = async (id, approved) => {
        const adRef = ref(database, `ads/${id}`);
        if (approved) {
            await update(adRef, { status: 'approved' });
            setAds(ads.map(ad => ad.key === id ? { ...ad, status: 'approved' } : ad));
        } else {
            const ad = ads.find(ad => ad.key === id);
            await deleteImage(ad.imageURL);
            await remove(adRef);
            setAds(ads.filter(ad => ad.key !== id));
        }
    };

    const handleDelete = async (id) => {
        const ad = ads.find(ad => ad.key === id);
        if (ad.imageURL) {
            const imageRef = storageRef(getStorage(), ad.imageURL);
            await deleteObject(imageRef);
        }
        await remove(ref(database, `ads/${id}`));
        setAds(ads.filter(ad => ad.key !== id));
    };

    const sortedAds = ads.sort((a, b) => {
        if (isAdmin) return a.status === 'pending' ? -1 : 1;
        return a.userId === currentUser.uid ? -1 : b.userId === currentUser.uid ? 1 : 0;
    });

    return (
        <Container>
            <Row>
                {sortedAds.map((ad) => (
                    (ad.status === 'approved' || isAdmin) && (
                        <Col key={ad.key} sm={12} md={6} lg={4}>
                            <Card className="mb-4">
                                <Card.Img variant="top" src={ad.imageURL} />
                                <Card.Body>
                                    <Card.Title>{ad.title}</Card.Title>
                                    <Card.Text>{ad.description}</Card.Text>
                                    <div className="w-100 d-flex justify-content-evenly">
                                        {isAdmin && ad.status === 'pending' && (
                                            <Button variant="success" onClick={() => handleApproval(ad.key, true)}>Approve</Button>
                                        )}
                                        {(currentUser && (currentUser.uid === ad.userId || isAdmin)) && (
                                            <Button variant="danger" onClick={() => handleDelete(ad.key)}>Delete</Button>
                                        )}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    )
                ))}
            </Row>
        </Container>
    );
};

export default AdView;