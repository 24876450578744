import React, {useEffect, useState} from 'react';
import {get, ref, remove} from 'firebase/database';
import {Button, Card, Col, Container, Row, Spinner} from 'react-bootstrap';
import useNavigate from "../../components/LanguageWrapper/Navigation";
import {auth, database} from "../../firebase";

const ReportedCommentsContainer = () => {
    const [reportedComments, setReportedComments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAdmin = async (user) => {
            const snapshot = await get(ref(database, `roles/comments`));
            if (snapshot.exists() && snapshot.val().includes(user.email)) {
                setIsAdmin(true);
                fetchReportedComments();
            } else {
                navigate("/");
            }
        };

        const unsubscribe = auth.onAuthStateChanged(user => user && checkAdmin(user));
        return unsubscribe;
    }, [navigate]);

    const fetchReportedComments = async () => {
        setLoading(true);
        const snapshot = await get(ref(database, `reported_comments`));
        const data = snapshot.val() || {};
        const comments = [];

        for (const articleName in data) {
            for (const commentId in data[articleName]) {
                const commentReport = data[articleName][commentId];
                const commentPath = commentReport.parent
                    ? `comments/${articleName}/${commentReport.parent}/replies/${commentId}`
                    : `comments/${articleName}/${commentId}`;

                const commentSnapshot = await get(ref(database, commentPath));
                if (commentSnapshot.exists()) {
                    comments.push({
                        id: commentId,
                        articleName,
                        ...commentSnapshot.val(),
                        commentPath,
                    });
                }
            }
        }

        setReportedComments(comments);
        setLoading(false);
    };

    const handleCardClick = (articleName, commentId) => {
        navigate(`/article/${articleName}?commentId=${commentId}`);
    };

    const handleRemoveComment = async (commentPath, commentId) => {
        await remove(ref(database, commentPath));
        await remove(ref(database, `reported_comments/${commentPath.split('/')[1]}/${commentId}`));
        fetchReportedComments();
    };

    if (!isAdmin) {
        return <p>You do not have permission to view reported comments.</p>;
    }

    return (
        <Container className="mt-4 text-white">
            <h3>Reported Comments</h3>
            {loading ? (
                <Spinner animation="border" />
            ) : reportedComments.length === 0 ? (
                <p>No reported comments.</p>
            ) : (
                <Row>
                    {reportedComments.map((comment) => (
                        <Col key={comment.id} md={6} lg={4} className="mb-4">
                            <Card className="bg-dark text-white">
                                <Card.Body>
                                    <Card.Title>{comment.displayName}</Card.Title>
                                    <Card.Text>{comment.text}</Card.Text>
                                    <div className="d-flex justify-content-between">
                                        <Button
                                            variant="danger"
                                            onClick={() => handleRemoveComment(comment.commentPath, comment.id)}
                                        >
                                            Remove Comment
                                        </Button>
                                        <Button
                                            variant="info"
                                            onClick={() => handleCardClick(comment.articleName, comment.id)}
                                        >
                                            View Comment
                                        </Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            )}
        </Container>
    );
};

export default ReportedCommentsContainer;