import React, {useEffect, useState} from "react";
import {auth, database} from "../../../firebase";
import {onValue, ref as databaseRef} from "firebase/database";
import SavedArticleData from "../SavedArticleData";

const SavedArticles = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                const dataRef = databaseRef(database, `users/${user.uid}/savedArticles/`);
                onValue(dataRef, snapshot => {
                    setData(snapshot.val());
                    setLoading(false);
                });
            } else {
                console.log("User is not logged in");
            }
        });
        return () => unsubscribe();
    }, []);

    return (
        <div className="container text-white">
            <h1>Saved Articles</h1>
            <div className="row mb-5">
                {loading ? (
                    <p>Loading...</p>
                ) : data ? (
                    Object.entries(data).map(([articleKey, articleData]) => (
                        <div className="col-md-4 mb-4" key={articleKey}>
                            <SavedArticleData article={articleKey} isSaved={articleData} />
                        </div>
                    ))
                ) : (
                    <p>No data to fetch</p>
                )}
            </div>
        </div>
    );
};

export default SavedArticles;