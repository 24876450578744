import React, {useEffect, useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './PrimaryCarousel.css';
import {Button, Modal} from "react-bootstrap";
import {getFirebaseStorageUrlFull} from "../../../systems/UploadSystem/articleData/articleData";
import {get, orderByChild, query, ref} from "firebase/database";
import {database} from "../../../firebase";

const PrimaryCarousel = ({ customSettings, customImages, classNameImages, shouldBeFull }) => {
    const [images, setImages] = useState([]);
    const [fullImages, setFullImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const fetchImages = async (imagePaths) => {
            try {
                const imageUrls = await Promise.all(imagePaths.map(imagePath => getFirebaseStorageUrlFull(imagePath, !!shouldBeFull)));
                setImages(imageUrls);

                if (!shouldBeFull) {
                    const fullImageUrls = await Promise.all(imagePaths.map(imagePath => getFirebaseStorageUrlFull(imagePath, true)));
                    setFullImages(fullImageUrls);
                }
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        const fetchData = async () => {
            try {
                const snapshot = await get(query(ref(database, '/gigs'), orderByChild('thumbnail')));
                const data = snapshot.val();
                const thumbnailList = Object.keys(data).map(itemKey => `/gigs/${itemKey}/${data[itemKey].thumbnail}`);
                fetchImages(thumbnailList);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (customImages?.length) {
            fetchImages(customImages);
        } else {
            fetchImages([
                "/gigs/2024_03_30/DSC_0008.JPG",
                "/gigs/2024_04_14/DSC_0048.JPG",
            ]).then(fetchData);
        }
    }, [customImages, shouldBeFull]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        ...customSettings
    };

    const handleImageClick = (index) => {
        setSelectedImage(fullImages[index]);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedImage(null);
    };

    return (
        <div className="carousel-container">
            <Slider {...settings}>
                {images.map((image, index) => (
                    <div key={index} className={`carousel-item ${classNameImages}`} onClick={() => handleImageClick(index)}>
                        <img src={image} alt={`slide${index}`} />
                    </div>
                ))}
            </Slider>

            <Modal size={"lg"} show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header className="d-flex bg-dark p-0" style={{ position: 'relative' }}>
                    <Button variant="link" onClick={handleCloseModal} style={{ position: 'absolute', top: '0px', right: '-40px', zIndex: 1 }}>
                        <span aria-hidden="true" style={{ color: 'white', fontSize: '2.5rem' }}>&times;</span>
                    </Button>
                    <img src={selectedImage} alt="Selected" style={{ width: '100%', height: 'auto' }} />
                </Modal.Header>
            </Modal>
        </div>
    );
};

export default PrimaryCarousel;