import toast, {Toaster} from "react-hot-toast";
import {onMessage} from 'firebase/messaging';
import {messaging} from "../../firebase";
import {useEffect} from "react";

function NotificationToast() {
    useEffect(() => {
        if (messaging) {
            return onMessage(messaging, ({ notification }) => {
                console.log("New message", notification.body);
                toast(`${notification.title}\n\n\n\n${notification.body}`);
            });
        }
    }, []);

    return <Toaster />;
}

export default NotificationToast;