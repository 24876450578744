import React, {useEffect, useState} from 'react';
import './PWA.css';

function InstallButton() {
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleBeforeInstallPrompt = (event) => {
            event.preventDefault();
            setDeferredPrompt(event);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        return () => window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    }, []);

    const installApp = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                console.log(`User ${choiceResult.outcome === 'accepted' ? 'accepted' : 'dismissed'} the installation`);
            });
        }
    };

    if (!isMobile) return null;

    return (
        <div className='nav-link text-white link' onClick={installApp}>
            Mobile App
        </div>
    );
}

export default InstallButton;