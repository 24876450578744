import React, {useEffect, useRef, useState} from 'react';
import {get, limitToLast, orderByChild, query, ref} from 'firebase/database';
import {getDownloadURL, ref as storageRef} from 'firebase/storage';
import {auth, database, storage} from '../../firebase';
import {NavLink, useParams} from 'react-router-dom';
import {Form, Spinner} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import Slider from 'react-slick';
import {AccordionComponent} from "./AccordionComponent";

const ArticlesList = () => {
    const { t } = useTranslation();
    const [articles, setArticles] = useState({});
    const [loading, setLoading] = useState(true);
    const [author, setAuthor] = useState({});
    const [borderSize, setBorderSize] = useState(6);
    const imgRef = useRef(null);
    const { authorCode } = useParams();
    const [galleryItems, setGalleryItems] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [batchSize] = useState(20);
    const [allArticles, setAllArticles] = useState([]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
        ],
    };

    useEffect(() => {
        const fetchData = async (loggedIn) => {
            try {
                let articlesRef = authorCode
                    ? ref(database, `authors/${authorCode}`)
                    : query(ref(database, 'articlesList'), orderByChild('date'), limitToLast(batchSize));

                let snapshot = await get(articlesRef);
                if (!snapshot.exists() && authorCode) {
                    articlesRef = ref(database, `users/${authorCode}`);
                    snapshot = await get(articlesRef);
                }

                let data = snapshot.val();
                if (authorCode) {
                    data.photoURL = await getDownloadURL(storageRef(storage, `/profile_images/${authorCode}_600x600`)).catch(() => "");
                    setAuthor(data);
                    fetchImagesFromGallery();
                }

                if (loggedIn && data.early_releases) {
                    data.articles.earlyReleases = Object.values(data.early_releases).reduce((acc, category) => ({ ...acc, ...category }), {});
                }

                if(!authorCode) {
                    setAllArticles(data);
                    setArticles(data);
                }else{
                    console.log(data);
                    setAllArticles(data.writtenArticles);
                    setArticles(data.writtenArticles);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching articles:', error);
                setLoading(false);
            }
        };

        auth.onAuthStateChanged(user => fetchData(!!user));
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authorCode, batchSize]);

    useEffect(() => {
        const updateBorderSize = () => setBorderSize(getBorderSize());
        window.addEventListener('resize', updateBorderSize);
        return () => window.removeEventListener('resize', updateBorderSize);
    }, []);

    const getBorderSize = () => {
        if (!imgRef.current) return 6;
        const { clientWidth: width, clientHeight: height } = imgRef.current;
        const percentage = width > 100 ? 0.06 : 0.03;
        return Math.min(width, height) * percentage;
    };

    const fetchImagesFromGallery = async () => {
        const snapshot = await get(ref(database, '/gallery/uploaded'));
        const data = snapshot.val() || {};
        const filteredData = Object.values(data).filter(d => d.title === authorCode);
        setGalleryItems(filteredData);
    };

    const handleSearch = (query) => {
        if (!allArticles.articles) return;
        const filteredArticles = Object.keys(allArticles.articles).reduce((acc, subCategory) => {
            const filteredSubCategory = Object.keys(allArticles.articles[subCategory])
                .filter(articleLink => {
                    const article = allArticles.articles[subCategory][articleLink];
                    return article.title.toLowerCase().includes(query.toLowerCase()) || article.content.toLowerCase().includes(query.toLowerCase());
                })
                .reduce((res, articleLink) => ({ ...res, [articleLink]: allArticles.articles[subCategory][articleLink] }), {});
            if (Object.keys(filteredSubCategory).length) acc[subCategory] = filteredSubCategory;
            return acc;
        }, {});
        setArticles({ articles: filteredArticles });
    };

    return (
        <div className="container mt-5 mb-5 overflow-hidden" style={{ marginBottom: '11.4vh' }}>
            {author.displayName && <Helmet><title>{author.displayName} - Pulse of the Underground</title></Helmet>}
            {loading ? (
                <Spinner animation="border" role="status" style={{ width: '100px', height: '100px', margin: 'auto' }}>
                    <span className="visually-hidden">{t('loadingMessage')}</span>
                </Spinner>
            ) : (
                <div>
                    {authorCode && (
                        <div className="mb-5">
                            <div className="row flex-column flex-md-row">
                                <div className="col-md-4 col-12 mb-5">
                                    <div className="w-75 mx-auto">
                                        <img
                                            ref={imgRef}
                                            className="img-fluid rounded-5"
                                            style={{ border: `${borderSize}px solid ${author.color || 'grey'}` }}
                                            src={author.photoURL}
                                            alt={author.displayName}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-8 col-12">
                                    <h1 className="card-title fw-bolder text-center text-light h1">{author.displayName}</h1>
                                    <hr className="bg-white text-white" />
                                    <div className="card-text text-light">{author.bio}</div>
                                </div>
                            </div>
                        </div>
                    )}
                    {galleryItems.length > 0 && (
                        <div className="mb-5">
                            <h2 className="text-white mb-2 text-center">{t('galleryTitle')}</h2>
                            <div className="container">
                                <Slider {...settings}>
                                    {galleryItems.map((image, index) => (
                                        <NavLink to={`${image.image}?fullScale=true`} key={index} className="nav-link bg-dark carousel-item">
                                            <img
                                                className="img-thumbnail"
                                                src={`https://pulse-of-the-underground.com${image.image}`}
                                                alt={`slide${index}`}
                                                style={{ maxHeight: '300px', objectFit: 'cover' }}
                                            />
                                        </NavLink>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    )}
                    <div className="d-flex justify-content-center mb-5 h-100 p-4">
                        <Form.Control
                            type="text"
                            className="bg-dark text-white w-75 rounded-3 h-100 p-3"
                            placeholder="Search articles"
                            value={searchQuery}
                            onChange={(e) => {
                                setSearchQuery(e.target.value);
                                handleSearch(e.target.value);
                            }}
                        />
                    </div>
                    {articles && <AccordionComponent articles={articles.articles} />}
                </div>
            )}
        </div>
    );
};

export default ArticlesList;