import React, {useEffect, useState} from 'react';
import {Alert, Button, Form, FormGroup, ListGroup, Modal} from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {auth, config, database, storage} from '../../../firebase';
import {signOut} from 'firebase/auth';
import {fetchAndActivate, getValue} from "firebase/remote-config";
import {onValue, ref as databaseRef} from "firebase/database";
import {useTranslation} from "react-i18next";
import {getDownloadURL, getMetadata, ref as storageRef, uploadString} from "firebase/storage";
import {categories, fetchFiles, handleAuthorTest} from "../articleData/articleData";
import useNavigate from "../../../components/LanguageWrapper/Navigation";
import CategoryDropdown from "../components/CategoryDropdown/CategoryDropdown";

const TranslationSystem = () => {
    const { t } = useTranslation();
    const [files, setFiles] = useState([]);
    const [alreadyPublishedArticles, setAlreadyPublishedArticles] = useState([]);
    const [earlyReleasedArticles, setEarlyReleasedArticles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [alreadyPublishedError, setAlreadyPublishedError] = useState('');
    const [earlyReleasesError, setEarlyReleasesError] = useState('');
    const [sortByDate, setSortByDate] = useState(false);
    const [user, setUser] = useState(null);
    const [fileData, setFileData] = useState({
        content: '',
        title: '',
        details: '',
        Socials: '',
        img01: '',
        sub: '',
        date: '',
        translations: {},
        lang: '',
        authorName: '',
    });
    const [translationData, setTranslationData] = useState({
        content: '',
        title: '',
        details: '',
        Socials: '',
        img01: '',
        sub: '',
        date: '',
        translations: {},
        authorName: '',
    });
    const [newLanguage, setNewLanguage] = useState('en');
    const [originalLanguage, setOriginalLanguage] = useState('');
    const [availableLanguages, setAvailableLanguages] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const roles = databaseRef(database, "/roles");
        onValue(roles, async (snapshot) => {
            const roles = snapshot.val();
            try {
                await fetchAndActivate(config);
            } catch (err) {
                console.log(err);
            }
            const serverLanguages = getValue(config, "languages").asString();
            setAvailableLanguages(JSON.parse(serverLanguages));

            const userList = [...Object.values(roles.translationSystem), ...Object.values(roles.admin)];
            const unsubscribe = auth.onAuthStateChanged((user) => {
                handleAuthorTest(user, setUser, navigate);
                if (!user || !userList.includes(user.email)) {
                    navigate('/upload');
                    signOut(auth).then();
                }
            });

            fetchFiles((files) => {
                setFiles(files["upload_from_authors"]);
                setEarlyReleasedArticles(files["early_releases"] || []);
                setAlreadyPublishedArticles(files["articles"]);
            }, setError, setAlreadyPublishedArticles, setAlreadyPublishedError, setEarlyReleasedArticles, setEarlyReleasesError, setLoading);

            return () => unsubscribe();
        });
    }, []);

    const checkIfStorageRefExists = async (fileRef) => {
        try {
            await getMetadata(fileRef);
            return true;
        } catch (error) {
            if (error.code === 'storage/object-not-found') {
                return false;
            } else {
                throw error;
            }
        }
    };

    const replaceSpecialCharsWithDashes = (text) => text.replace(/[^a-zA-Z0-9-\u0370-\u03FF\u1F00-\u1FFF]/g, '-');

    const updateOtherTranslations = async (relatedFileRef, newFileName) => {
        const downloadUrl = await getDownloadURL(relatedFileRef);
        const fileContent = await fetch(downloadUrl).then((res) => res.json());
        fileContent.translations[newLanguage] = newFileName;
        await uploadString(relatedFileRef, JSON.stringify(fileContent));
    }

    const handleSave = async () => {
        if (!selectedFile || !fileData) return;

        const originalFolder = selectedFile.folder;
        const translationFolder = `upload_from_authors`;
        let newFileName = `${replaceSpecialCharsWithDashes(translationData.title.replace(/\s+/g, ''))}-${newLanguage}.json`;
        let fileRef = storageRef(storage, `${translationFolder}/${newFileName}`);
        let translationFileRef = storageRef(storage, `${originalFolder}/${selectedFile.name}.json`);

        fileData.translations[newLanguage] = newFileName;
        fileData.translations[originalLanguage] = selectedFile.name;
        fileData.lang = originalLanguage;
        translationData.translations = fileData.translations;
        translationData.lang = newLanguage;
        translationData.translations[originalLanguage] = selectedFile.name;

        await uploadString(fileRef, JSON.stringify(translationData));
        const relatedTranslations = { ...fileData.translations, [newLanguage]: newFileName };
        await Promise.all(
            Object.keys(relatedTranslations).map(async (lang) => {
                let relatedFileName = relatedTranslations[lang];
                relatedFileName = relatedFileName.endsWith('.json') ? relatedFileName : `${relatedFileName}.json`;
                if (relatedFileName === newFileName) return;
                let relatedFileRef = storageRef(storage, `upload_from_authors/${relatedFileName}`);
                if (await checkIfStorageRefExists(relatedFileRef)) {
                    await updateOtherTranslations(relatedFileRef, newFileName);
                } else {
                    relatedFileRef = storageRef(storage, `articles/${relatedFileName}`);
                    if (await checkIfStorageRefExists(relatedFileRef)) {
                        await updateOtherTranslations(relatedFileRef, newFileName);
                    } else {
                        relatedFileRef = storageRef(storage, `early_releases/${relatedFileName}`);
                        if (await checkIfStorageRefExists(relatedFileRef)) {
                            await updateOtherTranslations(relatedFileRef, newFileName);
                        }
                    }
                }
            })
        );

        const contentToSave = { ...translationData, content: translationData.content.replaceAll('<p>', "<p class='lead'>").replaceAll("<img", "<img class='img-fluid'"), isReady: false };
        await uploadString(fileRef, JSON.stringify(contentToSave));
        await uploadString(translationFileRef, JSON.stringify(fileData));

        setFiles((prevFiles) => [
            ...prevFiles.filter((file) => file.name !== selectedFile.name),
            { name: newFileName, fileContent: contentToSave }
        ]);

        setShowModal(false);
        setTranslationData({
            content: '',
            title: '',
            details: '',
            Socials: '',
            img01: '',
            sub: '',
            date: '',
            translations: {},
            authorName: '',
        });
    };

    const handleTranslate = async (file) => {
        const articleRef = storageRef(storage, `${file.folder}/${file.name}.json`);
        const articleDownloadLink = await getDownloadURL(articleRef);
        const articleDataString = await fetch(articleDownloadLink);
        const fileData = await articleDataString.json();

        setSelectedFile(file);
        setFileData({ ...fileData });
        setShowModal(true);
        setTranslationData({ ...fileData });
        setOriginalLanguage(file.lang);

        if (fileData.sub) {
            const authorRef = databaseRef(database, `authors/${fileData.sub}`);
            onValue(authorRef, (snapshot) => {
                const authorData = snapshot.val();
                if (authorData && authorData.displayName) {
                    setFileData((prevData) => ({ ...prevData, authorName: authorData.displayName }));
                    setTranslationData((prevData) => ({ ...prevData, authorName: authorData.displayName }));
                }
            });
        }
    };

    const handleTranslationChange = (value, field) => {
        setTranslationData((prevData) => ({ ...prevData, [field]: value }));
    };

    const sortFilesByDate = (files) => files.sort((a, b) => new Date(b.date.split('/').reverse().join('-')) - new Date(a.date.split('/').reverse().join('-')));

    const handleShowList = (files, isEarlyRelease, isAlreadyPublished) => {
        const sortedFiles = sortByDate ? sortFilesByDate([...files]) : files;
        return (
            <ListGroup>
                {sortedFiles.map((file, index) => (
                    <ListGroup.Item key={index} className={`m-1 border-1 rounded-2 shadow-lg text-light ${file.isReady ? "bg-success" : "bg-dark"}`}>
                        <p className="form-label badge bg-dark-subtle text-dark m-1">{file.date}</p>
                        {file.title}
                        {file.translations && Object.keys(file.translations).length > 0 ? (
                            <div>
                                {Object.keys(file.translations).map((lang) => (
                                    <p key={lang} className={`form-label badge text-dark m-1 ${lang === file.lang ? 'bg-warning-subtle' : 'bg-dark-subtle'}`}>
                                        {availableLanguages[lang]}
                                    </p>
                                ))}
                            </div>
                        ) : (
                            <p>{t("noTranslationsAvailable")}</p>
                        )}
                        <Button variant="warning" onClick={() => handleTranslate(file, isAlreadyPublished, isEarlyRelease)}>
                            {t("translate")}
                        </Button>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        );
    };

    return (
        <div className="container">
            <div className="m-3 p-3 border">
                <h1 className="m-3 text-center text-white">{t("Translation System")}</h1>
                <div className="row">
                    <div className="col-lg-4">
                        <Button className="btn btn-light m-3" onClick={() => setSortByDate(!sortByDate)}>
                            {sortByDate ? t("default") : t("Sort by date")}
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="text-center text-white">{t("uploadedFiles")}</h3>
                        {files.length > 0 ? handleShowList(files, false, false) : <Alert>{error || t("No articles in this category")}</Alert>}
                    </div>
                    <div className="col-lg-12">
                        <h3 className="text-center text-white">{t("earlyReleases")}</h3>
                        {earlyReleasedArticles.length > 0 ? handleShowList(earlyReleasedArticles, true, false) : <Alert>{earlyReleasesError || t("No articles in this category")}</Alert>}
                    </div>
                    <div className="col-lg-12">
                        <h3 className="text-center text-white">{t("alreadyPublished")}</h3>
                        {alreadyPublishedArticles.length > 0 ? handleShowList(alreadyPublishedArticles, false, true) : <Alert>{alreadyPublishedError || t("No articles in this category")}</Alert>}
                    </div>
                </div>
            </div>
            <Modal size="xl" show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header className={"bg-dark text-white"} closeButton>
                    <Modal.Title>{fileData.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={"bg-dark text-white"}>
                    <Form>
                        <div>
                            <h5>{t('translate')}</h5>
                            <FormGroup controlId="translatedContent">
                                <Form.Label>{t('Content')}</Form.Label>
                                <ReactQuill theme="snow" value={translationData.content} onChange={(value) => handleTranslationChange(value, 'content')} />
                            </FormGroup>
                            <FormGroup controlId="translatedTitle">
                                <Form.Label>{t('Title')}</Form.Label>
                                <Form.Control className={"bg-dark text-white"} type="text" value={translationData.title} onChange={(e) => handleTranslationChange(e.target.value, 'title')} />
                            </FormGroup>
                            <FormGroup controlId="translatedDetails">
                                <Form.Label>{t('Details')}</Form.Label>
                                <Form.Control className={"bg-dark text-white"} type="text" value={translationData.details} onChange={(e) => handleTranslationChange(e.target.value, 'details')} />
                            </FormGroup>
                            <FormGroup controlId="translatedSub">
                                <Form.Label>{t('AuthorCode')} {fileData.authorName}</Form.Label>
                            </FormGroup>
                            <Form.Group controlId="categories">
                                <Form.Label className={"text-light"}>Category</Form.Label>
                                <CategoryDropdown categories={categories} onSelectCategory={(category) => handleTranslationChange(category, 'category')} required value={translationData.category} />
                            </Form.Group>
                            <FormGroup controlId="translatedLanguage">
                                <Form.Label>{t('translatedLanguageLabel')}</Form.Label>
                                <Form.Control className={"bg-dark text-white"} as="select" value={newLanguage} onChange={(e) => setNewLanguage(e.target.value)}>
                                    <option className={"bg-dark text-white"} value="">{t('selectLanguagePlaceholder')}</option>
                                    {Object.keys(availableLanguages).map((lang) => (
                                        <option className={"bg-dark text-white"} key={lang} value={lang}>{availableLanguages[lang]}</option>
                                    ))}
                                </Form.Control>
                            </FormGroup>
                        </div>
                        <Button className="btn btn-primary m-3" onClick={handleSave}>{t("Save")}</Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default TranslationSystem;