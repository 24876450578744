import {deleteObject, getDownloadURL, ref as storageRef, uploadString} from 'firebase/storage';
import {get, ref as databaseRef} from 'firebase/database';
import React, {useEffect, useState} from 'react';
import {Alert, Button, Card, Col, Form, ListGroup, Modal, Row, Toast} from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "./form-control.css";
import {auth, database, generateRecording, sendPushoverNotification, storage} from '../../../firebase';
import {signOut} from "firebase/auth";
import CategoryDropdown from "../components/CategoryDropdown/CategoryDropdown";
import {categories, deleteImage, fetchFiles, getRef, handleAuthorTest, setUids} from "../articleData/articleData";
import useNavigate from "../../../components/LanguageWrapper/Navigation";

const FirebaseFileList = () => {
    const [files, setFiles] = useState([]);
    const [alreadyPublishedArticles, setAlreadyPublishedArticles] = useState([]);
    const [earlyReleasedArticles, setEarlyReleasedArticles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const [sortByDate, setSortByDate] = useState(false);
    const [sortByCategory, setSortByCategory] = useState(false);
    const [authorName, setAuthorName] = useState('');
    const [translatorName, setTranslatorName] = useState('');
    const [socials, setSocials] = useState({});
    const [isAuthor, setIsAuthor] = useState(false);
    const [fileData, setFileData] = useState({
        content: '',
        title: '',
        details: '',
        Socials: '',
        img01: '',
        sub: '',
        date: '',
        lang: '',
        translations: {},
        isReady: false,
        sponsor: ""
    });
    const [error, setError] = useState('');
    const [alreadyPublishedError, setAlreadyPublishedError] = useState('');
    const [earlyReleasesError, setEarlyReleasesError] = useState('');
    const [leader, setIsLeader] = useState(true);
    const [showToast, setShowToast] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [contentChanged, setContentChanged] = useState(false);
    const [adminUids, setAdminUids] = useState([]);
    const [leadersUids, setLeadersUids] = useState([]);

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            handleAuthorTest(user, (user) => {}, navigate);
        });
        const rolesRef = databaseRef(database, '/roles');
        get(rolesRef).then(async (snapshot) => {
            const roles = snapshot.val();
            const userList = roles.admin || [];
            const leaderList = roles.authorLeader || [];
            setUids(userList, leaderList, setAdminUids, setLeadersUids);
            auth.onAuthStateChanged((user) => {
                setUser(user);
                if (user) {
                    setIsLeader(leaderList.includes(user.email) && !userList.includes(user.email));
                    setIsAuthor(!leaderList.includes(user.email) && !userList.includes(user.email));
                } else {
                    navigate('/upload');
                    signOut(auth).then();
                }
            });
        });
        fetchFiles((files) => {
            setFiles(files["upload_from_authors"]);
            setEarlyReleasedArticles(!files["early_releases"] ? [] : files["early_releases"]);
            setAlreadyPublishedArticles(files["articles"]);
        }, setError, setAlreadyPublishedArticles, setAlreadyPublishedError, setEarlyReleasedArticles, setEarlyReleasesError, setLoading).then();
    }, []);

    const copyLinkToClipboard = (link) => {
        navigator.clipboard.writeText(link).then(() => {
            setShowToast(true);
        });
    };

    const handleEdit = async (file) => {
        setLoading(true);
        try {
            const articleRef = storageRef(storage, `${file.folder}/${file.name}.json`);
            const articleDownloadLink = await getDownloadURL(articleRef);
            const articleDataString = await fetch(articleDownloadLink);
            const fileData = await articleDataString.json();

            const authorRef = databaseRef(database, `authors/${fileData.sub}`);
            const translatorRef = databaseRef(database, `authors/${fileData.translatedBy}`);
            const [authorSnapshot, translatorSnapshot] = await Promise.all([get(authorRef), get(translatorRef)]);

            setSelectedFile(file);
            setFileData(fileData);
            setSocials(fileData.socials || {});
            setAuthorName(authorSnapshot.exists() ? authorSnapshot.val().displayName || '' : '');
            setTranslatorName(translatorSnapshot.exists() ? translatorSnapshot.val().displayName || '' : '');
            setShowModal(true);
        } catch (error) {
            console.error('Error fetching file data:', error);
        } finally {
            setLoading(false);
        }
    };

// Optimized handleSave function
    const handleSave = async () => {
        if (contentChanged) {
            setContentChanged(false);
            const filePath = `${selectedFile.folder}/${selectedFile.name}.json`;
            generateRecording({ filePath }).then();
        }
        if (!selectedFile || !fileData) return;
        try {
            const fileRef = storageRef(storage, `${selectedFile.folder}/${selectedFile.name}.json`);
            const updatedContent = fileData.content.replaceAll('<p>', "<p class='lead'>").replaceAll('<img', "<img class='img-fluid'");
            await uploadString(fileRef, JSON.stringify({ ...fileData, content: updatedContent }));
            setAuthorName('');
            setSocials({});
            setShowModal(false);
            if (leader) {
                sendNotification(fileData.sub);
            }
            if (!leader) {
                leadersUids.forEach((uid) => {
                    sendNotification(uid);
                });
            }
            adminUids.forEach(uid => {
                sendNotification(uid);
            });
            alert('Changes saved successfully!');
        } catch (error) {
            setError('Error saving file data: ' + error.message);
        }
    };

    const sendNotification = (userId) => {
        sendPushoverNotification({
            uid: userId,
            title: `Article edited`,
            message: `Your article was edited by ${user.displayName} and is now ready for review by you.`,
            url: "https://pulse-of-the-underground.com/upload/admin"
        }).then((result) => {
            const data = result.data;
            if (data.success) {
                console.log("Notification sent successfully:", data.message);
            } else {
                console.error("Error sending notification:", data.message);
            }
        });
    };

    const handleChange = (e, field, isObject) => {
        let { value } = e.target;
        if (isObject) {
            value = JSON.parse(value);
        }
        setFileData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const handleContentChange = (value) => {
        setContentChanged(true);
        const sanitizedValue = value.replace(/<[^>]*style="[^"]*color:\s*[^";]*;?[^"]*"[^>]*>/g, '');
        setFileData((prevData) => ({
            ...prevData,
            content: sanitizedValue,
        }));
    };

    const handleSocialChange = (field, value) => {
        let { value: fieldValue } = value.target;
        setSocials((prevData) => ({
            ...prevData,
            [field]: fieldValue,
        }));
        const updatedSocials = {
            ...socials,
            [field]: fieldValue,
        };
        handleChange({ target: { value: JSON.stringify(updatedSocials) || '{}' } }, 'socials', true);
    };

    const handleDelete = async (file) => {
        const articleRef = storageRef(storage, `${file.folder}/${file.name}.json`);
        const articleDownloadLink = await getDownloadURL(articleRef);
        const articleDataString = await fetch(articleDownloadLink);
        const fileData = await articleDataString.json();
        const isConfirmed = window.confirm(`Are you sure you want to delete the file "${file.name}"?`);
        if (!isConfirmed) return;
        try {
            const fileRef = storageRef(storage, `${file.folder}/${file.name}.json`);
            if (fileData.translations && Object.keys(fileData.translations).length < 2) {
                const image = getRef(fileData.img01, false);
                deleteImage(image);
            }
            await deleteObject(fileRef);
        } catch (error) {
            setError('Error deleting file: ' + error.message + " " + JSON.stringify(file));
        }
    };

    const handleShowList = (files, isAlreadyPublished, isEarlyReleased) => {
        let sortedList = !files ? [] : [...files];
        if (sortByCategory) {
            sortedList.sort((a, b) => {
                const categoryA = a.category || 'Uncategorized';
                const categoryB = b.category || 'Uncategorized';
                return categoryA.localeCompare(categoryB);
            });
            const groupedByCategory = sortedList.reduce((acc, article) => {
                const category = article.category || 'Uncategorized';
                if (!acc[category]) {
                    acc[category] = [];
                }
                acc[category].push(article);
                return acc;
            }, {});
            return (
                <>
                    {Object.entries(groupedByCategory).map(([category, articles]) => (
                        <div key={category}>
                            <h5 className="text-white">{category}</h5>
                            {renderCategoryCards(articles)}
                        </div>
                    ))}
                </>
            );
        }
        if (sortByDate) {
            sortedList.sort((a, b) => {
                const dateA = new Date(a.date.split('/').reverse().join('-'));
                const dateB = new Date(b.date.split('/').reverse().join('-'));
                return dateB - dateA;
            });
        }
        return (
            <div className="row">
                {sortedList.map((file, index) => (
                    renderArticleCard(file, isAlreadyPublished, isEarlyReleased)
                ))}
            </div>
        );

        function renderCategoryCards(articles) {
            return (
                <div className="row g-4">
                    {articles.map((file, index) => (
                        <div key={index} className="col-auto">
                            {renderArticleCard(file, isAlreadyPublished, isEarlyReleased)}
                        </div>
                    ))}
                </div>
            );
        }

        function renderArticleCard(file, isAlreadyPublished, isEarlyReleased) {
            const articleLink = `/article/${isEarlyReleased ? 'early/' : ''}${file.name.replace('.json', '')}`;
            const cardTitle = file.title || 'Untitled';
            return (
                <Card className={`col-md-3 col-11 m-3 ${file.isReady ? 'bg-success' : file.authorApproved ? 'blink-bg' : 'bg-dark'}`}>
                    <Card.Body>
                        <Card.Title className="badge bg-dark-subtle text-dark">{file.date}</Card.Title>
                        <Card.Text>
                            {(isEarlyReleased || isAlreadyPublished) ? (
                                <a href={articleLink} onClick={() => copyLinkToClipboard(articleLink)}>{cardTitle}</a>
                            ) : (
                                <div className={"text-light"}>{cardTitle}</div>
                            )}
                        </Card.Text>
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                        {renderActionButtons(file, isAlreadyPublished, isEarlyReleased)}
                    </ListGroup>
                </Card>
            );
        }

        function renderActionButtons(file, isAlreadyPublished, isEarlyReleased) {
            const articleLink = `/article/${isEarlyReleased ? 'early/' : ''}${file.name.replace('.json', '')}`;
            console.log(file.translatedBy)
            if (((leader||(isAuthor&&(user.uid===(file.translatedBy?file.translatedBy:file.author)))) && !isAlreadyPublished && !isEarlyReleased) || (!leader&&!isAuthor)) {
                return (
                    <ListGroup.Item className="bg-dark text-white row d-flex justify-content-evenly">
                        <Button
                            variant="info"
                            className="col-3"
                            onClick={() => handleEdit(file, isAlreadyPublished, isEarlyReleased)}
                        >
                            Edit
                        </Button>

                        {(!leader&&!isAuthor) && <Button
                            className={"col-4"}
                            variant="danger"
                            onClick={() => handleDelete(file, isAlreadyPublished, isEarlyReleased)}
                        >
                            Delete
                        </Button>}

                        {(!isAlreadyPublished && isEarlyReleased && !leader&&!isAuthor) && (

                            <Button variant="success" onClick={() => {
                                handlePublish(false, file, isEarlyReleased).then();
                            }} className="col-4">
                                Normal
                            </Button>
                        )}


                        {(!isAlreadyPublished && !isEarlyReleased && !leader&&!isAuthor) && (
                            <>
                                <Button variant="success" onClick={() => {
                                    handlePublish(false, file, isEarlyReleased);
                                }}
                                        className={"col-3 justify-content-center"}>
                                    Early
                                </Button>

                                <Button variant="warning" onClick={() => {
                                    handlePublish(true, file, isEarlyReleased);
                                }}
                                        className={"col-4 justify-content-center"}>
                                    Normal
                                </Button>
                            </>
                        )}

                    </ListGroup.Item>
                );
            } else {
                return null;
            }
        }
    };

    const handlePublish = async (file) => {
        try {
            const fileRef = storageRef(storage, `${file.folder}/${file.name}.json`);
            const fileData = await (await fetch(await getDownloadURL(fileRef))).json();
            fileData.isReady = true;
            await uploadString(fileRef, JSON.stringify(fileData));
            alert('Article published successfully!');
        } catch (error) {
            setError('Error publishing file: ' + error.message);
        }
    };

    return (
        <>
            <div className="container mt-4">
                <div className="row d-flex align-items-center">
                    <div className="col-4">
                        <h2 className="text-white">Admin Publish System</h2>
                    </div>
                    <div className="col-8 d-flex justify-content-end">
                        <Form className="d-flex align-items-center">
                            <Form.Check
                                type="switch"
                                id="sort-by-date-switch"
                                label="Sort by Date"
                                checked={sortByDate}
                                onChange={() => setSortByDate(!sortByDate)}
                                className="me-3"
                            />
                            <Form.Check
                                type="switch"
                                id="sort-by-category-switch"
                                label="Sort by Category"
                                checked={sortByCategory}
                                onChange={() => setSortByCategory(!sortByCategory)}
                            />
                        </Form>
                    </div>
                </div>
                <hr className="bg-dark" />
                <div className="mb-4">
                    <h3 className="text-light mb-3">Uploaded Files <span className="text-info small">green background means ready for publishing</span></h3>
                    {error && <Alert variant="danger">{error}</Alert>}
                    {handleShowList(files, false, false)}
                </div>
                <div className="mb-4">
                    <h3 className="text-light mb-3">Early Releases <small className="text-info">Click on an article to copy the link</small></h3>
                    {earlyReleasesError && <Alert variant="danger">{earlyReleasesError}</Alert>}
                    {handleShowList(earlyReleasedArticles, false, true)}
                </div>
                <div className="mb-4">
                    <h3 className="text-light mb-3">Already Published <small className="text-info">Click on an article to copy the link</small></h3>
                    {alreadyPublishedError && <Alert variant="danger">{alreadyPublishedError}</Alert>}
                    {handleShowList(alreadyPublishedArticles, true, false)}
                </div>
                <Toast
                    onClose={() => setShowToast(false)}
                    show={showToast}
                    delay={3000}
                    autohide
                    style={{
                        position: 'fixed',
                        bottom: 20,
                        right: 20,
                        zIndex: 30000,
                    }}
                >
                    <Toast.Header>
                        <strong className="me-auto">Link Copied!</strong>
                    </Toast.Header>
                    <Toast.Body>The article link has been copied to the clipboard.</Toast.Body>
                </Toast>
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)} onExited={() => {
                setFileData({});
                setAuthorName("");
                setTranslatorName("");
            }}>
                <Modal.Header className={"bg-dark"} closeButton>
                    <Modal.Title className={"text-light"}>Edit File Data</Modal.Title>
                </Modal.Header>
                <Modal.Body className={"bg-dark"}>
                    <Form>
                        <Form.Group controlId="content">
                            <Form.Label className={"text-light"}>Content</Form.Label>
                            <ReactQuill
                                theme="snow"
                                key={selectedFile ? selectedFile.name : ''}
                                value={fileData.content}
                                onChange={handleContentChange}
                                style={{ color: "#a9a9a9" }}
                            />
                        </Form.Group>
                        <Form.Group controlId="title">
                            <Form.Label className={"text-light"}>Title</Form.Label>
                            <Form.Control
                                type="text"
                                className={"bg-dark text-white"}
                                value={fileData.title}
                                onChange={(e) => handleChange(e, 'title', false)}
                            />
                        </Form.Group>
                        <Form.Group controlId="details">
                            <Form.Label className={"text-light"}>Details</Form.Label>
                            <Form.Control
                                type="text"
                                className={"bg-dark text-white"}
                                value={fileData.details}
                                onChange={(e) => handleChange(e, 'details', false)}
                            />
                        </Form.Group>
                        <Form.Group controlId="socials">
                            <Form.Label className={"text-light"}>Social Media Links <span className={"small"}>(enter only those available)</span></Form.Label>
                            <Row>
                                <Col>
                                    <Form.Label className={"text-light"}>Facebook:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className={"bg-dark text-white"}
                                        placeholder="Facebook"
                                        value={socials.facebook}
                                        onChange={(e) => handleSocialChange('facebook', e)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label className={"text-light"}>Instagram:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className={"bg-dark text-white"}
                                        placeholder="Instagram"
                                        value={socials.instagram}
                                        onChange={(e) => handleSocialChange('instagram', e)}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <Form.Label className={"text-light"}>Spotify:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className={"bg-dark text-white"}
                                        placeholder="Spotify"
                                        value={socials.spotify}
                                        onChange={(e) => handleSocialChange('spotify', e)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label className={"text-light"}>YouTube:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className={"bg-dark text-white"}
                                        placeholder="YouTube"
                                        value={socials.youtube}
                                        onChange={(e) => handleSocialChange('youtube', e)}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group controlId="img01">
                            <Form.Label className={"text-light"}>Image URL</Form.Label>
                            <Form.Control
                                type="text"
                                className={"bg-dark text-white"}
                                value={fileData.img01}
                                onChange={(e) => handleChange(e, 'img01', false)}
                            />
                        </Form.Group>
                        <Form.Group controlId="sub">
                            <Form.Label className={"text-light"}>Author</Form.Label>
                            <Form.Control
                                type="text"
                                className={"bg-dark text-white"}
                                value={authorName}
                                onChange={(e) => handleChange(e, 'sub', false)}
                            />
                        </Form.Group>
                        <Form.Group controlId="date">
                            <Form.Label className={"text-light"}>Date</Form.Label>
                            <Form.Control
                                type="text"
                                className={"bg-dark text-white"}
                                value={fileData.date}
                                onChange={(e) => handleChange(e, 'date', false)}
                            />
                        </Form.Group>
                        <Form.Group controlId="lang">
                            <Form.Label className={"text-light"}>Language</Form.Label>
                            <Form.Control
                                type="text"
                                className={"bg-dark text-white"}
                                value={fileData.lang}
                                onChange={(e) => handleChange(e, 'lang', false)}
                            />
                        </Form.Group>
                        <Form.Group controlId="sponsor">
                            <Form.Label className={"text-light"}>Sponsor</Form.Label>
                            <Form.Control
                                type="text"
                                className={"bg-dark text-white"}
                                value={fileData.sponsor}
                                onChange={(e) => handleChange(e, 'sponsor', false)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className={"bg-dark"}>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default FirebaseFileList;