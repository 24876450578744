import React, {useEffect, useState} from 'react';
import {getDownloadURL, ref as storageRef} from "firebase/storage";
import {onValue, ref as dbRef} from "firebase/database";
import {auth, database, storage} from "../../firebase";
import NavLink from "../LanguageWrapper/NavLink";
import {useTranslation} from 'react-i18next';

const RecommendationSystem = () => {
    const { t } = useTranslation();
    const [articles, setArticles] = useState([]);

    const fetchCategories = (userId) => {
        return new Promise((resolve) => {
            const userSavedArticlesRef = dbRef(database, `users/${userId}/savedArticles`);
            onValue(userSavedArticlesRef, async (snapshot) => {
                const savedArticlesData = snapshot.val();
                if (savedArticlesData) {
                    const categories = await Promise.all(
                        Object.keys(savedArticlesData).map(async (key) => {
                            const folder = savedArticlesData[key].isEarlyAccess ? 'early_releases' : 'articles';
                            const articleData = await fetchArticleData(folder, key);
                            return articleData?.category;
                        })
                    );
                    resolve(categories.filter(Boolean));
                } else {
                    resolve([]);
                }
            });
        });
    };

    const fetchArticleData = async (folder, articleName) => {
        try {
            const url = await getDownloadURL(storageRef(storage, `${folder}/${articleName}.json`));
            const response = await fetch(url);
            return await response.json();
        } catch (error) {
            console.error(`${t('errorFetchingArticle')} ${error}`);
            return null;
        }
    };

    const readAllArticles = async (categories) => {
        try {
            const allArticles = [];
            await Promise.all(categories.map(async (category) => {
                const categoryRef = dbRef(database, `articles/${category}`);
                onValue(categoryRef, (snapshot) => {
                    if (snapshot.exists()) {
                        const categoryData = snapshot.val();
                        Object.entries(categoryData).forEach(async ([articleName, articleData]) => {
                            if (articleData.isPublished) {
                                const folder = articleData.isEarlyAccess ? 'early_releases' : 'articles';
                                const article = await fetchArticleData(folder, articleName);
                                if (article && !allArticles.find((a) => a.name === articleName)) {
                                    article.link = `/article/${articleName}`;
                                    allArticles.push(article);
                                    setArticles([...allArticles]);
                                }
                            }
                        });
                    } else {
                        console.log(t('noDataAvailable'));
                    }
                });
            }));
            return allArticles;
        } catch (error) {
            console.error('Error reading articles:', error);
            return [];
        }
    };

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                fetchCategories(user.uid).then(readAllArticles);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <h2>{t('favoriteCategories')}</h2>
            {articles.map((article, index) => (
                <div className="card bg-white" key={index}>
                    {article.img01 && <img className="img-fluid" src={article.img01} alt={article.title} />}
                    <div className="card-body">
                        <h5 className="card-title">{article.title}</h5>
                        <NavLink className="btn btn-danger" to={article.link}>{t('readMore')}</NavLink>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default RecommendationSystem;