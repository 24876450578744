import React, {useEffect, useState} from "react";
import NavLink from "../LanguageWrapper/NavLink";
import {useTranslation} from "react-i18next";
import {limitToLast, onValue, orderByChild, query, ref} from "firebase/database";
import {database} from "../../firebase";

const ReadMore = ({ category, isEarlyAccess }) => {
    const { t } = useTranslation();
    const [latestArticlesOnCategory, setLatestArticlesOnCategory] = useState([]);

    const cleanUpHTML = (html) => {
        const div = document.createElement('div');
        div.innerHTML = html.trim();

        const processNode = (node) => {
            if (node.nodeType === Node.ELEMENT_NODE) {
                if (node.tagName.toLowerCase() === 'p') {
                    let lastChild = node.lastChild;
                    while (lastChild && lastChild.nodeType === Node.ELEMENT_NODE && lastChild.tagName.toLowerCase() === 'br') {
                        node.removeChild(lastChild);
                        lastChild = node.lastChild;
                    }
                    if (node.innerHTML.trim() === '') {
                        node.parentNode.removeChild(node);
                    }
                }
                node.childNodes.forEach(child => processNode(child));
            }
        };

        div.childNodes.forEach(child => processNode(child));
        div.querySelectorAll("*:empty").forEach(x => x.remove());
        return div.innerHTML;
    };

    const truncateHTML = (html, wordLimit) => {
        const div = document.createElement('div');
        div.innerHTML = html;

        let wordCount = 0;
        const nodeStack = [];
        const result = [];

        const processNode = (node) => {
            if (node.nodeType === Node.TEXT_NODE) {
                const words = node.textContent.trim().split(/\s+/);
                for (let i = 0; i < words.length; i++) {
                    if (wordCount >= wordLimit) break;
                    result.push(words[i]);
                    wordCount++;
                }
            } else if (node.nodeType === Node.ELEMENT_NODE) {
                nodeStack.push(node);
                result.push(`<${node.tagName.toLowerCase()}${[...node.attributes].map(attr => ` ${attr.name}="${attr.value}"`).join('')}>`);
                node.childNodes.forEach(child => processNode(child));
                nodeStack.pop();
                result.push(`</${node.tagName.toLowerCase()}>`);
            }
        };

        div.childNodes.forEach(node => processNode(node));
        while (nodeStack.length) {
            const node = nodeStack.pop();
            result.push(`</${node.tagName.toLowerCase()}>`);
        }

        let finalResult = result.join(' ').replace(/\s+/g, ' ').trim();
        finalResult = finalResult.replace(/<p[^>]*>(\s|<br\s*\/?>)*<\/p>/g, '');
        finalResult = finalResult.replace(/(<br\s*\/?>\s*)+<\/p>/g, '</p>');
        finalResult = finalResult.replace(/<br\s*\/?>\s*$/, '');

        return cleanUpHTML(finalResult);
    };

    const setListener = (category, isEarlyAccess) => {
        const latestCategoryRef = ref(database, `articlesList/${isEarlyAccess ? "early_releases" : "articles"}/${category}`);
        const latestArticlesQuery = query(latestCategoryRef, orderByChild('date'), limitToLast(3));
        return onValue(latestArticlesQuery, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setLatestArticlesOnCategory(data);
            }
        });
    };

    useEffect(() => {
        const unsubscribe = [
            setListener(category, false),
            isEarlyAccess && setListener(category, true)
        ].filter(Boolean);

        return () => unsubscribe.forEach(unsub => unsub());
    }, [category, isEarlyAccess]);

    return (
        <div className="container">
            <h4>Pulse Of The Underground</h4>
            <div className="row mt-4 mb-5 text-center">
                {Object.keys(latestArticlesOnCategory).map((articleLink) => {
                    const article = latestArticlesOnCategory[articleLink];
                    article.link = `/article${isEarlyAccess ? "/early" : ""}/${articleLink}`;
                    return (
                        <div key={articleLink} className="col-md-4 mb-4">
                            <div className="card h-100 w-100 bg-dark text-white">
                                <img className="card-img-top shadow-lg img-fluid" src={article.image} alt={article.title} />
                                <div className="card-header">
                                    <h3 className="card-title fw-bold">{article.title}</h3>
                                </div>
                                <div className="card-body">
                                    <NavLink to={article.link} className="btn btn-primary">{t('readMore')}</NavLink>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ReadMore;