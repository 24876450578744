import React from 'react';
import {Route} from 'react-router-dom';
import LanguageWrapper from './components/LanguageWrapper/LanguageWrapper';
import DefaultArticle from './components/GenericArticle/GenericArticle';
import Gallery from './pages/Gallery/Gallery';
import Home from './pages/Home';
import LegendV0L2 from './pages/articles/Aleah';
import GeorgeKollias from './pages/articles/GeorgeKollias';
import AcidMamoth from './pages/articles/Interviews/AcidMamoth';
import HollerInterview from './pages/articles/Interviews/Holler';
import KhavarInterview from './pages/articles/Interviews/Khavar';
import Primordial from './pages/articles/Primordial-black-interview';
import ArticleUpload from './systems/UploadSystem/UploadSystem';
import Login from './systems/UploadSystem/Login/Login';
import Register from './systems/UploadSystem/Register/Register';
import FirebaseFileList from './systems/UploadSystem/VerificationSystem/VerificationSystem';
import TranlationSystem from './systems/UploadSystem/TranslationSystem/TranlationSystem';
import SavedArtciles from './components/Users/Pages/Saved';
import UserProfile from './systems/UploadSystem/Profile/UserProfile';
import RecommendationSystem from './components/RecommendationSystem/RecomendationSystem';
import AdminSystem from './systems/AdminSystem/AdminSystem';
import UploadGalleryItem from './pages/Gallery/uploadArt/UploadGalleryItem';
import ArticlesList from './pages/ArticlesList/ArticlesList';
import NotFound from './pages/NotFound/NotFound';
import GigsPage from './pages/GigsPage/GigsPage';
import GigDetailPage from './pages/GigsPage/GigDetailPage';
import AdsPage from './systems/ads/AdsPage';
import ReportedCommentsContainer from './pages/CommentReportSystem/ReportedCommentsContainer';
import NotificationsPage from './pages/Notifications/NotificationsPage';
import Youtube from './components/YoutubeAPI/Youtube';

export const getRoutes = (langPathShouldExist, menuVisible) => {
        return (
            <Route path={langPathShouldExist ? "/:lang" : "/"} element={<LanguageWrapper />}>
                    <Route path='' element={<Home isMenuVisible={menuVisible} />} />
                    <Route path='articles-page' element={<ArticlesList />} />
                    <Route path='Art-Gallery-page' element={<Gallery />} />
                    <Route path='legends-2-archive' element={<LegendV0L2 />} />
                    <Route path='Falooda-interview-archive' element={<Primordial />} />
                    <Route path='Holler-interview-archive' element={<HollerInterview />} />
                    <Route path='Khavar-interview-archive' element={<KhavarInterview />} />
                    <Route path='Acid-Mammoth-interview-archive' element={<AcidMamoth />} />
                    <Route path='legends-5-archive' element={<GeorgeKollias />} />
                    <Route path='article/:name' element={<DefaultArticle earlyAccess={false} />} />
                    <Route path='article/early/:name' element={<DefaultArticle earlyAccess={true} />} />
                    <Route path='recommended' element={<RecommendationSystem />} />
                    <Route path='admin' element={<AdminSystem />} />
                    <Route path='gallery/upload' element={<UploadGalleryItem />} />
                    <Route path='upload' element={<ArticleUpload />} />
                    <Route path='upload/register' element={<Register />} />
                    <Route path='profile' element={<UserProfile />} />
                    <Route path='upload/admin' element={<FirebaseFileList />} />
                    <Route path='upload/login' element={<Login admin={false} />} />
                    <Route path='upload/admin/login' element={<Login admin={true} />} />
                    <Route path='upload/translation' element={<TranlationSystem />} />
                    <Route path='User/login' element={<Login admin={false} />} />
                    <Route path='User/register' element={<Register />} />
                    <Route path='User/Saved' element={<SavedArtciles />} />
                    <Route path='author/:authorCode' element={<ArticlesList />} />
                    <Route path={"404"} element={<NotFound />} />
                    <Route path="gigs" element={<GigsPage />} />
                    <Route path="gigs/:date" element={<GigDetailPage />} />
                    <Route path="ads" element={<AdsPage />} />
                    <Route path="admin/comments" element={<ReportedCommentsContainer />} />
                    <Route path="youtube" element={<Youtube />} />
                    <Route path="notifications" element={<NotificationsPage />} />
            </Route>
        );
};