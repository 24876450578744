import React, {useEffect, useState} from 'react';
import {
    getIdTokenResult,
    GithubAuthProvider,
    GoogleAuthProvider,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut
} from 'firebase/auth';
import {Alert, Button, Col, Container, Form, Row} from 'react-bootstrap';
import {auth} from '../../../firebase';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';
import useNavigate from "../../../components/LanguageWrapper/Navigation";

const Login = (props) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [emailVerification, setEmailVerification] = useState(false);
    const navigate = useNavigate();
    const [user, setUser] = useState(auth.currentUser);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                if (!user.emailVerified) {
                    await signOut(auth);
                } else {
                    const idTokenResult = await getIdTokenResult(user);
                    if (!idTokenResult) {
                        await signOut(auth);
                    }
                    navigate(idTokenResult.claims?.admin ? '/upload' : '/User/home');
                }
            }
        });
        return () => unsubscribe();
    }, [navigate]);

    const handleUserLoggedIn = async (user) => {
        setUser(user);
        if (!user.emailVerified) {
            setError(t('verifyEmail'));
        } else {
            if (props.admin && user.email === "tzimasvaggelis02@gmail.com") {
                navigate("/upload/admin");
            }
            const idTokenResult = await user.getIdTokenResult(true);
            navigate(idTokenResult.claims?.admin ? '/upload' : '/User/home');
        }
    };

    const handleError = (error) => {
        setError(error.code === 'auth/account-exists-with-different-credential' ? t("This email has logged in with a different provider") : error.message);
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            await handleUserLoggedIn(userCredential.user);
        } catch (error) {
            handleError(error);
        }
    };

    const sendVerification = async () => {
        try {
            await sendEmailVerification(user);
            setError(t("verifyEmail"));
            setEmailVerification(false);
        } catch (error) {
            setError(error.message);
        }
    };

    const signInWithProvider = async (provider) => {
        try {
            const user = await signInWithPopup(auth, provider);
            await handleUserLoggedIn(user.user);
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <Container>
            <Row className="justify-content-center mt-5">
                <Col md={6}>
                    <h2 className="text-center mb-4 text-white">{t('login')}</h2>
                    {error && (
                        <Alert variant="danger" className="d-flex justify-content-center">
                            {error}
                            {emailVerification && (
                                <Button onClick={sendVerification} className="m-1 btn btn-sm btn-warning">
                                    {t('sendEmailVerification')}
                                </Button>
                            )}
                        </Alert>
                    )}
                    <Form className="card bg-dark w-100 text-white p-4" onSubmit={handleLogin}>
                        <Form.Group controlId="email">
                            <Form.Label column>{t('email')}</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder={t('enterEmail')}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="password">
                            <Form.Label colum>{t('password')}</Form.Label>
                            <div className="input-group">
                                <Form.Control
                                    type={showPassword ? "text" : "password"}
                                    placeholder={t('enterPassword')}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <Button variant="outline-secondary" onClick={() => setShowPassword(!showPassword)}>
                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                </Button>
                            </div>
                        </Form.Group>
                        <Form.Group className="mt-4">
                            <Button variant="danger" onClick={() => signInWithProvider(new GoogleAuthProvider())}>
                                {t('loginWithGoogle')}
                            </Button>
                            <Button variant="secondary" className="bg-dark" onClick={() => signInWithProvider(new GithubAuthProvider())}>
                                {t('loginWithGithub')}
                            </Button>
                        </Form.Group>
                        <Form.Group>
                            <Button variant="link" onClick={async () => {
                                try {
                                    await sendPasswordResetEmail(auth, email);
                                    setError(t('sendPasswordReset'));
                                    setEmailVerification(false);
                                } catch (error) {
                                    setError(error.code === "auth/missing-email" ? t('enterEmail') : error.message);
                                }
                            }}>
                                {t('forgotPassword')}
                            </Button>
                        </Form.Group>
                        <Button variant="secondary" type="button" onClick={() => navigate("/upload/register")}>
                            {t('register')}
                        </Button>
                        <Button variant="primary" type="submit" className="w-100 mt-3">
                            {t('login')}
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default Login;